.differencial_card_container {
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  border-radius: 16px;
  border: 1px solid #dadada;
  padding: 32px 24px;
}

.differencial_card_image {
  padding: 40px 0 24px 0;
}

.differencial_card_icon {
  height: 44px;
  width: 44px;
}

.differencial_card_title {
  font-size: 24px;
}

.differencial_card_wrapper {
  display: flex;
  padding: 20px 0;
}

.differencial_card_text {
  padding-left: 16px;
}

.differencial_card_text > h3 {
  font-size: 20px;
  font-weight: 600;
  width: 140px;
}

.differencial_card_text > p {
  font-size: 16px;
  color: var(--grey-lighter);
  width: 90%;
}

@media (min-width: 768px) {
  .differencial_card_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 0;
    border-radius: 0;
    gap: 16px;
    background-color: transparent;
    padding: 0;
  }

  .differencial_card_wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    border-radius: 36px;
    width: 180px;
    padding: 44px 32px;
  }

  .differencial_card_icon {
    height: 64px;
    width: 64px;
  }

  .differencial_card_title {
    text-align: center;
    font-size: 14px;
    margin-top: 8px;
  }

  .differencial_card_text {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    padding: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
}

@media (min-width: 1024px) {
  .differencial_card_wrapper {
    width: 220px;
    height: 280px;
  }

  .differencial_card_wrapper {
    padding: 44px 36px;
  }

  .differencial_card_text > p {
    font-size: 14px;
  }

  .differencial_card_text > h3 {
    font-size: 14px;
  }
}

@media (min-width: 1440px) {
  .differencial_card_wrapper {
    width: 268px;
    height: 320px;
  }

  .differencial_card_wrapper {
    padding: 44px 36px;
  }

  .differencial_card_text > p {
    font-size: 16px;
  }

  .differencial_card_text > h3 {
    font-size: 18px;
    width: 200px;
  }
}

@media (min-width: 1920px) {
  .differencial_card_wrapper {
    width: 360px;
    height: 420px;
  }

  .differencial_card_wrapper {
    padding: 44px 36px;
  }

  .differencial_card_text > p {
    font-size: 24px;
  }

  .differencial_card_text > h3 {
    font-size: 32px;
    width: 200px;
  }
}