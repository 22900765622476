.record_card_container {
  display: flex;
  flex-direction: column;
  background-color: var(--black);
  border-radius: 16px;
  border: 1px solid #dadada;
  padding: 32px 24px;
}

.record_card_image {
  padding: 0 24px;
}

.record_card_container > h2 {
  font-size: 32px;
  padding: 42px 0 24px 0;
  color: var(--off-whiter);
}

.record_card_container > p {
  font-size: 20px;
  font-weight: 300;
  color: var(--off-whiter);
  width: 90%;
  padding-bottom: 60px;
}

.button_wrapper {
  display: flex;
}

@media (min-width: 768px) {
  .record_card_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--black);
    border-radius: 16px;
    border: 1px solid #dadada;
    padding: 64px;
    gap: 16px;
  }

  .record_card_wrapper > h2 {
    font-size: 24px;
    color: var(--off-whiter);
  }
  
  .record_card_wrapper > p {
    font-size: 16px;
    font-weight: 300;
    color: var(--off-whiter);
    width: 90%;
    padding: 16px 0 54px 0;
  }

  .record_card_image {
    padding: 0;
    height: 220px;
    object-fit: cover;
  }
}

@media (min-width: 1024px) {
  .record_card_container {
    padding: 80px;
  }

  .record_card_wrapper > h2 {
    font-size: 32px;
  }
  
  .record_card_wrapper > p {
    font-size: 20px;
  }

  .record_card_image {
    height: 280px;
  }
}

@media (min-width: 1440px) {
  .record_card_container {
    padding: 100px 110px 80px 100px;
  }

  .record_card_wrapper > h2 {
    width: 80%;
    font-size: 48px;
  }
  
  .record_card_wrapper > p {
    font-size: 24px;
    font-weight: 300;
    color: var(--off-whiter);
    width: 90%;
    padding: 16px 0 54px 0;
  }

  .record_card_image {
    height: 380px;
  }
}