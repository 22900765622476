/* Reset some default browser styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    overflow-x: clip;
}

/* Global styles */
body {
    font-family: 'Inter', Arial, sans-serif;
}

a {
    text-decoration: none;
    color: inherit;
}

/* Global Style for Video Item */

.react-player__preview {
    height: 320px !important;
    background-size: cover !important;
    border: 2px solid var(--off-whiter);
    border-radius: 8px;
    transform: rotate(270deg);
}

.react-player__shadow {
    transform: rotate(90deg);
}

video {
    object-fit: cover;
}