.video_container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 3fr));
  background-color: var(--white);
}

@media (min-width: 768px) {
  .video_container {
    padding: 24px 8px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 50%));
  }
}

@media (min-width: 1024px) {
  .video_container {
    padding: 56px 32px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 33%));
  }
}

@media (min-width: 1440px) {
  .video_container {
    padding: 56px 72px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 25%));
  }
}

@media (min-width: 1920px) {
  .video_container {
    padding: 56px 96px;
  }
}