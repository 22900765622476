.arena_card_container {
  display: flex;
  flex-direction: column;
  width: 240px;
  background-color: var(--white);
  border-radius: 16px;
}

.arena_card_image {
  height: 180px;
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  object-fit: cover;
  object-position: 100% 25%;
}

.arena_card_wrapper {
  padding: 16px;
}

.arena_card_title {
  font-size: 14px;
  font-weight: 600;
}

.arena_card_description {
  min-height: 54px;
  font-size: 12px;
  color: var(--grey-lighter);
  margin-bottom: 12px;
}

.arena_card_button {
  border: 1px solid #dadada;
  padding: 4px 16px;
  border-radius: 32px;
  font-size: 12px;
}

@media (min-width: 1024px) {
  .arena_card_wrapper {
    padding: 20px;
  }
}

@media (min-width: 1440px) {
  .arena_card_container {
    width: 340px;
    height: 460px;
  }

  .arena_card_wrapper {
    padding: 24px;
  }

  .arena_card_image {
    height: 268px;
    width: 100%;
  }

  .arena_card_title {
    font-size: 20px;
  }

  .arena_card_description {
    min-height: 70px;
    font-size: 14px;
  }

  .arena_card_button {
    border: 1px solid #dadada;
    padding: 8px 32px;
    border-radius: 32px;
    font-size: 16px;
  }
}

@media (min-width: 1920px) {
  .arena_card_container {
    width: 400px;
    height: 600px;
  }

  .arena_card_wrapper {
    padding: 36px;
  }

  .arena_card_image {
    height: 360px;
    width: 100%;
  }

  .arena_card_title {
    font-size: 24px;
  }

  .arena_card_description {
    min-height: 80px;
    font-size: 20px;
  }

  .arena_card_button {
    border: 1px solid #dadada;
    padding: 8px 32px;
    border-radius: 32px;
    font-size: 22px;
  }
}