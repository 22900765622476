.accept_terms {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.accept_terms > label {
  font-size: 14px;
  margin: 0 0 0 8px;
  font-weight: 400;
}

.accept_terms > label > span {
  font-size: 14px;
  text-decoration: underline;
  color: var(--orange);
  font-weight: 500;
}

.accept_terms > input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.checkbox {
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  background-color: #FFC58D;
  border: 2px solid #E3A061;
  transition: border 0.3s ease, background-color 0.3s ease;
  border-radius: 2px;
}

.accept_terms input:checked + .checkbox {
  border: 2px solid var(--toranja);
  background-color: var(--toranja);
}