.button {
    display: flex;
    position: relative;
    justify-content: center;
    border: 0;
    padding: 8px 24px;
    border-radius: 8px;
    font-size: 14;
    font-weight: 600;
    z-index: 2;
}

.button_white {
    background-color: var(--black);
    color: var(--white);
}

.button_black {
    background-color: var(--white);
    color: var(--black);
}

.button:disabled {
    background-color: var(--grey-lighter);
}