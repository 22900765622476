.video_item_container {
    display: flex;
    flex-direction: column;
    padding: 24px 52px;
  }
  
  .video_wrapper {
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    object-fit: cover;
  }

  .video_actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0 0 0;
  }
  
  .replay_time {
    font-size: 16px;
    color: var(--black);
    margin: 0;
    font-weight: 600;
  }

  .replay_time > span {
    font-size: 16px;
    color: var(--grey-lighter);
  }
  
  .download_button {
    background-color: var(--orange);
    color: var(--white);
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
  
  .download_button:hover {
    background-color: var(--toranja);
  }

  @media (min-width: 320px) {
    .video_item_container {
        padding: 24px;
      }
  }

  @media (min-width: 375px) {
    .video_item_container {
      padding: 24px 52px;
      }
  }

  @media (min-width: 768px) {
    .video_item_container {
        padding: 24px;
      }
  }