.dropdownContainer {
  width: 100%;
  position: relative;
}

.dropdownHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  border: 1.8px solid var(--light-grey);
  padding: 16px;
  cursor: pointer;
  border-radius: 8px;
  font-weight: 600;
}

.dropdownHeader > span {
  font-size: 16px;
}

.dropdownListContainer {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  right: 0;
  background-color: var(--white);
  border: 1.8px solid var(--light-grey);
  border-radius: 8px;
  z-index: 1;
}

.dropdownList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdownItem {
  padding: 16px;
  cursor: pointer;
  border-radius: 8px;
}

.dropdownItem:hover {
  /* TODO: Check this color */
  background-color: #c3c3c3;
}

.dropdownItem:first-child {
  border-radius: 8px 8px 0 0;
}

.dropdownItem:last-child {
  border-radius: 0 0 8px 8px;
}

.dropdownItem:not(:last-child) {
  border-bottom: 1.8px solid var(--light-grey);
}

@media (min-width: 768px) {
  .dropdownContainer {
    width: 148px;
    position: relative;
  }
}

@media (min-width: 1024px) {
  .dropdownContainer {
    width: 230px;
    position: relative;
  }
}

@media (min-width: 1440px) {
  .dropdownContainer {
    width: 340px;
    position: relative;
  }
}

@media (min-width: 1920px) {
  .dropdownContainer {
    width: 500px;
    position: relative;
  }
}