.signup_form {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 40px 16px;
    flex: 1;
}

.signup_form > h2 {
    font-size: 24px;
    padding-bottom: 32px;
}

.button_signup {
    width: 100%;
    margin-top: 24px;
}

.error_message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

@media (min-width: 768px) {
    .signup_container {
        display: flex;
    }

    .signup_form {
        padding: 56px 56px 40px 56px;
    }

    .button_signup {
        width: 80%;
    }
}

@media (min-width: 1024px) {
    .button_signup {
        width: 240px;
    }

    .signup_image {
        display: flex;
        width: 40%;
    }
}

@media (min-width: 1440px) {
    .button_signup {
        width: 300px;
    }

    .signup_image {
        display: flex;
        width: 35%;
    }
}

@media (min-width: 1920px) {
    .button_signup {
        width: 300px;
    }

    .signup_image {
        display: flex;
        width: 30%;
    }
}

