.main_container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.container {
    position: absolute;
    padding: 0 16px 76px 16px;
    top: -92px;
    left: 0;
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: space-between;
    background-image: url('../../assets/images/main-banner-mobile.webp');
    background-size: cover;
    height: 100vh;
}

@media (max-width: 768px) {
    .container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.8;
    background: linear-gradient(215deg, rgb(229, 118, 5, 0.5), rgb(242, 155, 42, 1));
    z-index: 0; 
  }
}

.watch_videos_text_container {
    margin-top: 40vh;
}

.high_quality_video_text {
    position: relative;
    font-size: 48px;
    color: var(--off-whiter);
    font-weight: bold;
    z-index: 2;
    width: 80vw;
}

.your_plays_text {
    position: relative;
    font-size: 24px;
    margin-bottom: 80px;
    color: var(--off-whiter);
    font-style: italic;
    z-index: 2;
}

.videos_container {
    display: flex;
    width: 100%;
    position: relative;
    margin-top: calc(100vh - 92px);
    padding: 40px 16px 40px 16px;
    overflow-x: scroll;
    scroll-snap-type: x mandatory; 
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.videos_container::-webkit-scrollbar {
    display: none; 
}

.videos_wrapper {
    display: flex;
    width: max-content;
    gap: 16px;
}

.texts_wrapper {
    position: relative;
    padding: 40px 16px;
}

.texts_wrapper > h2 {
    font-size: 20px;
    margin-bottom: 16px;
}

.texts_wrapper > p {
    font-size: 16px;
    padding-bottom: 40px;
    width: 90%;
}

.differencial_container {
    position: relative;
    padding: 40px 16px;
}

.record_container {
    padding: 40px 16px;
}

.gradient {
    position: absolute;
    left: 10vw;
    top: 30vh;
    z-index: -1;
}

.gradient_secondary {
    position: absolute;
    right: 40vw;
    top: 90vh;
    z-index: -1;
}

@media (min-width: 768px) {
    .container {
        top: -128px;
        position: relative;
        padding: 0 64px;
        height: 482px;
        display: flex;
        align-items: start;
        justify-content: space-between;
        background-image: none;
        background-color: linear-gradient(to left, var(--orange_gradient_start), var(--orange_gradient_end));
    }

    .container::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 594px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background: linear-gradient(to left, var(--orange_gradient_start), var(--orange_gradient_end));
        z-index: 0; 
      }
    
    .woman_playing_volleyball_img {
        position: relative;
        width: 420px;
        margin-top: 120px;
    }
    
    .watch_videos_text_container {
        display: flex;
        flex-direction: column;
        margin-top: 180px;
    }
    
    .high_quality_video_text{
        font-size: 46px;
        color: var(--white);
        width: 400px;
    }
    
    .your_plays_text {
        font-size: 22px;
        margin-bottom: 40px;
        color: var(--white);
        font-style: italic;
    }

    .videos_container {
        margin-top: 0;
        padding: 40px 0;
    }

    .videos_wrapper {
        justify-content: space-between;
        width: 100%;
        padding: 0 56px;
    }

    .texts_container {
        display: flex;
        position: relative;
        justify-content: space-between;
        padding: 56px 0;
    }

    .texts_wrapper {
        display: flex;
        flex-direction: column;
        padding: 0 56px;
        width: 520px;
    }

    .texts_wrapper > h2 {
        font-size: 26px;
        margin-bottom: 16px;
    }
    
    .texts_wrapper > p {
        font-size: 18px;
        padding-bottom: 40px;
    }

    .differencial_card_image {
        padding-right: 56px;
        object-fit: contain;
        width: 386px;
    }

    .gradient {
        position: absolute;
        left: 40vw;
        top: 8vh;
        z-index: -1;
        width: 50%;
    }

    .know_more_button {
        width: 200px;
        padding: 12px;
    }

    .differencial_container {
        background: linear-gradient(to left, var(--orange_gradient_start), var(--orange_gradient_end));
        padding: 64px 56px;
    }

    .record_container {
        padding: 64px 56px;
    }
}

@media (min-width: 1024px) {
    .texts_wrapper {
        width: 540px;
    }

    .texts_wrapper > h2 {
        font-size: 26px;
        margin-bottom: 16px;
    }
    
    .texts_wrapper > p {
        font-size: 18px;
        padding-bottom: 40px;
    }

    .videos_wrapper {
        padding: 0 56px;
    }

    .differencial_card_image {
        padding-right: 56px;
        object-fit: contain;
        width: 420px;
    }

    .gradient {
        position: absolute;
        left: 50vw;
        top: -3vh;
        z-index: -1;
        width: 45%;
    }
}

@media (min-width: 1440px) {
    .container {
        padding: 0 96px;
    }

    .high_quality_video_text {
        width: 480px;
        font-size: 64px;
    }

    .texts_wrapper {
        padding: 0 0 0 96px;
        width: 680px;
    }

    .your_plays_text {
        font-size: 32px;
    }

    .texts_wrapper > h2 {
        font-size: 30px;
        margin-bottom: 16px;
    }
    
    .texts_wrapper > p {
        font-size: 20px;
        padding-bottom: 40px;
    }

    .videos_wrapper {
        padding: 0 96px;
    }

    .differencial_container {
        padding: 96px;
    }

    .differencial_card_image {
        padding-right: 96px;
        object-fit: contain;
        width: 520px;
    }

    .gradient {
        position: absolute;
        left: 50vw;
        top: -5vh;
        z-index: -1;
        width: 50%;
    }

    .record_container {
        padding: 64px 96px;
    }
}

@media (min-width: 1920px) {
    .container {
        padding: 0 128px;
    }

    .container::before {
        height: 920px;
    }

    .videos_container {
        margin-top: calc(35vh);
    }

    .high_quality_video_text {
        width: 640px;
        font-size: 80px;
    }

    .your_plays_text {
        font-size: 40px;
    }

    .woman_playing_volleyball_img {
        position: relative;
        width: 680px;
        margin-top: 152px;
    }

    .texts_wrapper {
        padding: 0 0 0 128px;
        width: 680px;
    }

    .texts_wrapper > h2 {
        font-size: 32px;
        margin-bottom: 16px;
    }
    
    .texts_wrapper > p {
        font-size: 24px;
        padding-bottom: 40px;
    }

    .differencial_container {
        padding: 96px 128px;
    }

    .videos_wrapper {
        padding: 0 128px;
    }

    .differencial_card_image {
        padding-right: 128px;
        object-fit: contain;
        width: 600px;
    }

    .gradient {
        position: absolute;
        left: 55vw;
        top: -10vh;
        z-index: -1;
        width: 45%;
    }
}