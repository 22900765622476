  body {
    background-color: var(--off-whiter);
  }

  h2 {
    font-size: 32px;
    font-weight: 700;
    margin: 0;
  }

  span {
    font-size: 20px;
    font-weight: 600;
  }

  label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .search_form_container {
    display: flex;
    flex-direction: row;
    padding: 16px 16px 64px 16px;
  }

  .search_form_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .form {
    display: flex;
    gap: 16px;
    flex-direction: column;
    padding: 40px 0;
  }

  .form_wrapper {
    display: flex;
    gap: 24px;
    flex-direction: row;
  }

  .form_group {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .button_group {
    display: flex;
  }



  @media (min-width: 768px) {
    .search_form_container {
      display: flex;
      flex-direction: row;
      gap: 16px;
      padding: 40px 0 0 0;
    }

    .form_group {
      width: auto;
    }
  }

  @media (min-width: 768px) {
    .form {
      padding: 56px 56px 40px 0;
    }
  }
  
  @media (min-width: 1024px) {
    .form {
      padding: 56px 56px 40px 0;
    }

    .search_image {
      width: 480px;
    }
  }
  
  @media (min-width: 1440px) {
    .form {
      padding: 56px 96px 40px 0;
    }

    .search_form_wrapper {
      padding-left: 80px;
    }

    .search_image {
      width: 520px;
    }
  }
  
  @media (min-width: 1920px) {
    .form {
      padding: 56px 128px 40px 0;
    }

    .search_image {
      width: 648px;
    }
  }


  


