.footer {
    position: relative;
    display: flex;
    padding: 40px 16px;
    flex-direction: column;
    background: #F7F7F7;
} 

.footer_home {
    background: transparent;
}

.newsletter_wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-around;
    gap: 12px;
}

.newsletter_wrapper > h2 {
    font-size: 24px;
    font-weight: 800;
}

.newsletter_wrapper > p {
    font-size: 20px;
    margin-bottom: 20px;
    width: 90%;
}

.links {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;
    justify-content: start;
}

.links_wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-around;
    gap: 12px;
}

.contacts {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
}

.contacts_wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.contacts_row {
    display: flex;
    gap: 16px;
    align-items: center;
}

.copyright {
    display: flex;
    justify-content: center;
    font-size: 12px;
    padding: 28px 40px 0 28px;
    position: relative;
    color: var(--black);
    font-weight: 500;
}

.copyright::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 30%, rgba(0, 0, 0, 0.5) 60%, rgba(0, 0, 0, 0) 100%)
}

a {
    color: var(--black);
    font-weight: 500;
    font-size: 15px;
}

p {
    margin: 0;
    font-size: 16px;
}

.links_wrapper > h2 {
    font-size: 24px;
    font-weight: 800;
}

.gradient_footer {
    position: absolute;
    left: -25vw;
    top: -5vh;
    z-index: -1;
}

@media (min-width: 768px)  {
    .footer {
        gap: 64px;
        padding: 40px 56px;
        /* TODO: Check this color in design system */
        background: #F7F7F7;
    }

    .footer_wrapper {
        display: flex;
        justify-content: space-between;
    }

    a {
        font-size: 16px;
    }

    p {
        font-size: 16px;
    }

    .links_wrapper {
        justify-content: normal;
        margin-top: 12px;
    }

    .contacts_wrapper {
        flex: 1;
    }

    .contacts_row {
        display: flex;
        gap: 8px;
        align-items: center;
    }
}

@media (min-width: 1440px)  {
    .footer {
        padding: 40px 96px;
    }
}

@media (min-width: 1440px)  {
    .footer {
        padding: 40px 128px;
    }
}