.inputFieldContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    flex: 1;
    position: relative;
  }
  
  .inputLabel {
    font-size: 16px;
    color: var(--black);
  }
  
  .inputField {
    width: 100%;
    padding: 10px;
    border: 2px solid #ddd;
    border-radius: 8px;
    font-size: 15px;
    color: var(--black);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  }

  .inputError {
    border: 2px solid var(--red);
  }
  
  .inputField::placeholder {
    /* TODO: Check this color */
    color: #c1c1c1;
  }

  .errorText {
    color: var(--red);
    font-size: 14px;
    margin-top: 6px;
  }

  .inputButton {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--black);
    border: none;
    height: 42px;
    width: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 8px;
  }

  .buttonIcon {
    width: 20px;
    height: 20px;
  }