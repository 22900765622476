.login_form {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 40px 16px;
    flex: 1;
}

.login_form > h2 {
    font-size: 24px;
    padding-bottom: 32px;
}

.divider_group {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 0 24px 0;
}

.divider_text {
    padding: 0 8px;
    font-size: 16px;
    color: var(--black);
}

.divider {
    display: flex;
    flex: 1;
    height: 2px;
    background: #c2c2c2;
}

.button_login {
    width: 100%;
    margin-top: 16px;
}

.error_message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

.signup {
    padding-top: 12px;
    font-size: 14px;
}

.signup > a {
    text-decoration: underline;
    color: var(--orange);
    font-size: 14px;
}

.forgot_password {
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.warning_login {
    font-size: 12px; 
    color: var(--grey);
    line-height: 16px;
    margin-top: 12px;
}

@media (min-width: 768px) {
    .login_container {
        display: flex;
    }

    .login_form {
        padding: 56px 56px 40px 56px;
    }

    .button_login {
        width: 80%;
    }
}

@media (min-width: 1024px) {
    .button_login {
        width: 240px;
    }

    .login_image {
        display: flex;
        width: 40%;
    }
}

@media (min-width: 1440px) {
    .button_login {
        width: 300px;
    }

    .login_image {
        display: flex;
        width: 35%;
    }
}

@media (min-width: 1920px) {
    .login_image {
        display: flex;
        width: 30%;
    }
}

