.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: var(--orange);
  border: 2px solid var(--orange);
  border-radius: 8px;
  padding: 14px 24px;
  gap: 10px;
  height: 56px;
}

.button_text {
  font-weight: 600;
  font-size: 18px;
  color: var(--black);
}

.button:hover {
  border-color: var(--toranja);
}

.button:active {
  background: var(--toranja);
}

.button_black {
  background: var(--black);
  border: 2px solid var(--white);
}

.button_black > .button_text {
  color: var(--white);
}

.button_black:hover {
  background: var(--black-dark);
  border: 2px solid var(--white);
  border-color: var(--white);
}

.button:disabled {
  background: #b2b2b2;
  border: 2px solid #b2b2b2;
}
