  body {
    background-color: var(--off-whiter);
  }

  h2 {
    font-size: 36px;
    font-weight: 700;
  }

  span {
    font-size: 20px;
    font-weight: 600;
  }

  label {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 8px;
  }

.video_search_container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px 16px 64px 16px;
  }
  
  .info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .info > p {
    padding-right: 8px;
    font-size: 20px;
    color: var(--black);
    font-weight: 600;
  }

  span {
    font-size: 20px;
    color: var(--grey-lighter);
    font-weight: 500;
  }
  
  .form {
    display: flex;
    gap: 24px;
    flex-direction: column;
  }
  
  .form_group {
    display: flex;
    flex-direction: column;
  }

  input[type="date"] {
    padding: 16px;
    border: 1.8px solid var(--light-grey);
    border-radius: 8px;
    background-color: var(--white);
    font-size: 16px;
    color: var(--black);
  }
  
  input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    filter: invert(0.1);
  }


  @media (min-width: 768px) {
    .form {
      flex-direction: row;
      align-items: end;
    }

    .video_search_container {
      padding: 40px 0 0 0;
    }

    .info {
      display: flex;
      justify-content: start;
    }

    .form_group {
      gap: 8px;
      justify-content: space-between;
    }

  }

  @media (min-width: 768px) {
    .video_search_container {
      padding: 56px 16px;
    }

    input[type="date"] {
      width: 160px;
    }
  }
  
  @media (min-width: 1024px) {
    .video_search_container {
      padding: 56px 56px;
    }

    input[type="date"] {
      width: 160px;
    }
  }
  
  @media (min-width: 1440px) {
    .video_search_container {
      padding: 56px 96px;
    }

    input[type="date"] {
      width: 280px;
    }
  }
  
  @media (min-width: 1920px) {
    .video_search_container {
      padding: 56px 96px;
    }

    input[type="date"] {
      width: 400px;
    }
  }
  


