.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: var(--orange);
  border: 2px solid var(--orange);
  border-radius: 42px;
  padding: 8px 24px;
  height: 42px;
  width: 112px;
  color: var(--black);
  font-size: 14px;
  font-weight: 600;
}

.button:hover {
  border-color: var(--toranja);
}

.button:active {
  opacity: 0.9;
}
