.navbar {
    position: relative;
    width: 100%;
    padding: 24px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    background: linear-gradient(to left, var(--orange_gradient_start), var(--orange_gradient_end));
}

.navbar_transparent {
  background: transparent;
}

.navbar_container_menu {
    width: 550px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
}

.logo{
    width: 127px;
}

.navbar_container_login {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar_links {
  font-weight: 300;
}

.navbar_white {
    background: var(--off-whiter)
}

.active {
    font-weight: bold;
  }

.navbar_links_black {
    color: var(--white);
  }

.navbar_links_transparent {
  color: var(--white);
}

@media (min-width: 768px) {
    .navbar {
        padding: 56px 32px;
        height: 128px;
    }
  }
  
  @media (min-width: 1024px) {
    .navbar {
      padding: 56px 32px;
    }
  }
  
  @media (min-width: 1440px) {
    .navbar {
      padding: 56px 96px;
    }
  }
  
  @media (min-width: 1920px) {
    .navbar {
      padding: 56px 128px;
    }
  }


/* Drawer CSS */

.drawer_container {
  padding: 40px 0;
}

.drawer_header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
  margin: 0 24px 16px 24px;
}

.drawer_header > a {
  display: flex;
  align-items: center;
}

.drawer_header > a > h2 {
  margin-left: 10px;
  font-size: 18px;
}

.profile_image {
  border-radius: 50%;
  width: 40px;
}

.drawer_list {
  list-style-type: none;
  padding: 0;
}

.drawer_list li {
  display: flex;
  align-items: center;
  padding: 20px 24px;
  cursor: pointer;
  transition: background 0.3s;
}

.drawer_list li:last-child {
  position: absolute;
  bottom: 80px;
  width: 100%;
}

.drawer_list li > a {
  font-size: 18px;
  color: var(--grey-lighter);
  display: flex;
  align-items: center;
}

.drawer_list li:hover {
  background-color: #f0f0f0;
}

.drawer_list li svg {
  margin-right: 10px;
}

.screen_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.drawer_list > .active {
  background: linear-gradient(to right, rgb(255, 237, 209, 1), rgb(255, 237, 209, .25));
  border-left: 4px solid var(--orange);
  padding-left: 20px;
}

.drawer_list > .active > a {
  color: var(--orange);
}

.close_icon {
  font-size: 24px;
  color: '#6A6A6A';
   margin-left: 18px; 
}

li.active > a > .home_icon {
  color: var(--orange);
}

li.active > a > .people_icon {
  color: var(--orange);
}

li.active > a > .camera_icon {
  color: var(--orange);
}

li.active > a > .mail_icon {
  color: var(--orange);
}

li.active > a > .faq_icon {
  color: var(--orange);
}

li.active > a > .videos_icon {
  color: var(--orange);
}


