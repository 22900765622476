:root{
    --orange_gradient_start: #e57605;

    --orange_gradient_end: #f29b2a;

    --white: #FFFFFF;
    
    --off-whiter: #F2F2F2;

    --black-dark: #1E1E1E;
    
    --black: #232323;

    --grey-lighter: #6A6A6A;

    --grey-light: #3F3F3F;

    --yellow-light: #FFD04F;

    --orange: #F58A25;

    --toranja: #F2632F;

    --red: #EB482B;

    --blue-mid: #2E7B9C;
}